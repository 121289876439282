<template>
    <layout>
        <page-header :title="title" :items="items" />

        <awesome-table
            id="gift-cards"
            :labels="labels"
            :items="giftCards"
            :options="{
                addButton: false,
                searchInput: false,
                sort: true,
                filters: true,
                columnsSelector: true
            }"
            :pagination="pagination"
            :filters="filters"
            :get-row-class="getRowClass"
            @pagination-change="onPaginationChange"
            @filter-change="onFilterChange"
        >
            <template #[`items.type`]="{ value }">
                <span :class="getBadgeClass(value)">
                    {{ value === 'quantity' ? 'QUANTITY' : 'VALUE' }}
                </span>
            </template>
            <template #[`items.initial_value`]="{ item, value }">
                <span v-if="item.type === 'quantity'">
                    {{
                        value * 100 > 1
                            ? `${value * 100} pairs`
                            : `${value * 100} pair`
                    }}
                </span>
                <span v-else>
                    ${{ value * 100 | variantFixedPrice }}
                </span>
            </template>
            <template #[`items.current_value`]="{ item, value }">
                <span v-if="item.type === 'quantity'">
                    {{
                        value * 100 > 1
                            ? `${value * 100} pairs`
                            : `${value * 100} pair`
                    }}
                </span>
                <span v-else>
                    ${{ value * 100 | variantFixedPrice }}
                </span>
            </template>
            <template #[`items.actions`]="{ item }">
                <router-link
                    v-b-tooltip.hover.top
                    title="Orders"
                    class="action-icon"
                    :to="`/gift-cards/${item.id}/orders`"
                >
                    <i class="fas fa-file-invoice-dollar" />
                </router-link>
                <a
                    v-if="item.status !== 'BLOCKED'"
                    v-b-tooltip.hover.top
                    title="Block"
                    href="#"
                    class="action-icon"
                    @click.stop.prevent="onBlockGiftCard(item.id)"
                >
                    <i class="fas fa-minus-circle" />
                </a>
            </template>
        </awesome-table>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';

export default {
    page: {
        title: 'Gift Cards',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Gift Cards',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Gift Cards',
                    active: true
                }
            ],
            codeToUpdate: null,
            giftCards: [],
            labels: [
                {
                    text: 'Order Number',
                    value: 'order.order_number',
                    filterable: false
                },
                'code',
                {
                    value: 'type',
                    filterable: false
                },
                {
                    text: 'Purchase value',
                    value: 'initial_value',
                    filter: 'variantFixedPrice'
                },
                {
                    text: 'Current Status',
                    value: 'current_value',
                    filter: 'variantFixedPrice'
                },
                {
                    text: 'Last used',
                    value: 'updated_at',
                    filter: 'formatDate',
                    type: 'date'
                },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    type: 'date'
                },
                { value: 'actions', sortable: false, filterable: false }
            ],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
        };
    },

    mounted() {
        this.fetchGiftCards();
    },

    methods: {
        ...mapActions({
            getGiftCards: 'giftCards/index',
            blockGiftCard: 'giftCards/block'
        }),

        async fetchGiftCards() {
            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters
                };

                const { count, rows } = await this.getGiftCards(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.giftCards = rows;
                this.pagination.pages = Math.ceil(
                    count / this.pagination.perPage
                );
                this.pagination.total = count;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async onBlockGiftCard(id) {
            const confirmed = await this.$confirmationModal(
                'Are you sure you want to block this Gift Card Code?'
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.blockGiftCard(id);

                this.fetchGiftCards();

                this.$toaster('Code has been blocked!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchGiftCards();
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchGiftCards();
        },

        getRowClass({ status }) {
            return status === 'BLOCKED' ? 'table-danger' : '';
        },

        getBadgeClass(value) {
            let color = 'info';

            if (value === 'quantity') {
                color = 'primary';
            }

            return `badge bg-soft-${color} text-${color}`;
        }
    }
};
</script>
